@import-normalize;

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "PingFang SC", "Hiragino Sans GB", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

html,
body {
  margin: 0;
  background-color: #fff;
}
